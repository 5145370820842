//  TODO temporary solution until Piler will load / expose the used components
export const usedEEComponents = [
	'WRichText',
	'AppWidget',
	'VectorImage',
	'StylableButton',
	'ImageX',
	'VerticalLine',
	'MultiStateBox',
	'SiteButton',
]

export const usedEESkins = ['Repeater_Responsive', 'Container_ResponsiveBox', 'SiteButton_WrappingButton']
