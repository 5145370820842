import { createPromise } from './promiseUtils'

export function getCompClassType(componentType: string, uiType?: string) {
	return uiType ? `${componentType}_${uiType}` : componentType
}

const viewer_components_id_prefixes = [
	'MENU_AS_CONTAINER_TOGGLE',
	'MENU_AS_CONTAINER_EXPANDABLE_MENU',
	'BACK_TO_TOP_BUTTON',
	'SCROLL_TO_',
	'TPAMultiSection_',
	'TPASection_',
	'comp-',
	'TINY_MENU',
	'MENU_AS_CONTAINER',
	'SITE_HEADER',
	'SITE_FOOTER',
	'SITE_PAGES',
	'PAGES_CONTAINER',
	'BACKGROUND_GROUP',
	'POPUPS_ROOT',
]

const comp_type_attribute = 'data-comp-type'

export function getClosestCompIdByHtmlElement(htmlElement: HTMLElement): string {
	let closestElement
	for (const prefix of viewer_components_id_prefixes) {
		closestElement = htmlElement.closest(`[id^="${prefix}"]`)
		if (closestElement) {
			break
		}
	}
	return closestElement?.id || ''
}

export function extractClosestCompTypeFromHtmlElement(htmlElement: HTMLElement): string {
	const closestElement = htmlElement.closest(`[${comp_type_attribute}]`)
	return closestElement?.getAttribute(comp_type_attribute) || ''
}

export type LazyComponentTrigger = 'viewport'
export type LazyComponentOptions = {
	triggers?: Array<LazyComponentTrigger>
	componentResolver: () => Promise<React.ComponentType<any>>
}
export type LazyComponentLoaderResponse = {
	componentPromise: Promise<React.ComponentType<any>>
	clearComponentListeners: () => void
}

export const lazyComponentLoaderFactory = ({ triggers = ['viewport'], componentResolver }: LazyComponentOptions) => (
	id: string
): LazyComponentLoaderResponse => {
	const targetElement = document.getElementById(id)

	if (!targetElement) {
		// If there was a mismatch (or navigation) we want to hydrate immediatly and not wait for intersection
		// as the target element might be null and promise will remain hanging
		return {
			componentPromise: componentResolver(),
			clearComponentListeners: () => {},
		}
	}

	const listeners: Array<Promise<void>> = []
	const cleaners: Record<string, Function> = {}

	if (triggers.includes('viewport')) {
		const { promise: viewportPromise, resolver } = createPromise()
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting && entry.intersectionRatio > 0) {
					cleaners.viewport()
					delete cleaners.viewport
					resolver()
				}
			},
			{ root: null }
		)
		observer.observe(targetElement)
		cleaners.viewport = () => {
			observer.disconnect()
		}
		listeners.push(viewportPromise)
	}

	return {
		componentPromise: new Promise<React.ComponentType<any>>(async (resolve) => {
			await Promise.all(listeners)
			resolve(componentResolver())
		}),
		clearComponentListeners: () => Object.values(cleaners).forEach((c) => c()),
	}
}
